<template>
  <div>
    <div class="">
      <!--------------------- loading --------------------->
      <Loading class="section-dark section-loading"></Loading>
      <!--------------------- section --------------------->
      <section v-if="!isLoading" key="content" class="section-duke jpadding jpadding-20" id="payments">
        <div class="jtitle jtitle-main duke-style">
          <h1>Payments</h1>
        </div>

        <!-- Search and Filters -->
        <SearchAndFilters :searchList="payments" :filters="filters" :startingSearchText="searchText"></SearchAndFilters>

        <!--------------------- table --------------------->
        <!-- TABLE -->
        <div v-if="payments.length" key="list" class="table-responsive">
          <table class="table wht">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Product</th>
                <th scope="col">Status</th>
                <th scope="col">Watch Model</th>
                <th scope="col">Watch Serial</th>
                <th scope="col">ID</th>
                <th scope="col">Price</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(payment, index) in payments" :key="payment.id">
                <td>{{ payment.date }}</td>
                <td>{{ payment.product }}</td>
                <td>{{ payment.status }}</td>
                <!-- <td><a @click="gotoWatch(index)"> {{ payment.watchModel }}</a></td> -->
                <td>{{ payment.watchModel }}</td>
                <td>
                  <router-link v-if="watchIds[index]" :to="{ name: 'MyWatch', params: { watchId: watchIds[index] } }">{{
                    payment.watchSerial }}</router-link>
                </td>
                <td>
                  <small>{{ payment.id }}</small>
                </td>
                <td>{{ payment.priceString }}</td>
                <td>
                  <!-- <button class="jbtn jbtn-icon jbtn-white mr-2" v-if="payment.status == 'succeeded'"
                    @click="showReceipt(index)">
                    <i class="fal fa-file-alt"></i>
                  </button> -->
                  <button class="jbtn jbtn-icon jbtn-white" v-if="payment.status == 'succeeded'"
                    @click="generatePdf(index)">
                    <i class="fal fa-file-pdf"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!--------------------- empty --------------------->
        <div v-else key="empty" class="section-empty">
          <h5 class="mb-3"><i class="fad fa-empty-set fa-2x"></i><br /></h5>
          <p class="p-text-s">
            This list is empty at the moment.
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SearchAndFilters from "@/components/general/SearchAndFilters";
import Loading from "@/components/general/Loading";
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const auth = firebase.auth;

export default {
  name: "Payments",
  components: {
    Loading: Loading,
    SearchAndFilters
  },
  data() {
    return {
      isLoading: true,
      isEmpty: false,
      isGeneratingPdf: false,
      paymentsLoaded: false,
      watchesLoaded: false,
      modelsLoaded: false,

      // data for list & search
      realPayments: [],
      payments: [],
      watchesMap: new Map(),
      watcheModelsMap: new Map(),
      searchText: "",
      // data for links
      receipts: [],
      watchIds: [],

      // parameters for filter component
      filters: []
    };
  },
  methods: {
    loadingDone(error) {
      if (!error) {
        console.log("loading done without error");
        this.finalizeListData();

        //get payment id from params if we are entering component, while looking for a specific payment
        if (this.$route.params.paymentId != null && this.$route.params.paymentId != "") {
          this.searchText = C.getTrimmedId(this.$route.params.paymentId);
          console.log("Searchtext prop: " + this.searchText);
        }
      }

      this.$root.$emit("loadingDone", error);
      if (!error) this.isLoading = false;
    },
    loadPayments() {
      const id = auth.currentUser.uid;
      db.collection(C.COLLECTION.USERS)
        .doc(id)
        .collection(C.COLLECTION.PAYMENTS)
        .orderBy("created", "desc")
        .get()
        .then(querySnapshot => {
          this.realPayments.splice(0);
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.addPaymentToList(docdata);
            this.realPayments.push(docdata);
          });

          console.log("payments loaded");
          this.addToFilters(C.FILTER_TYPE.DATE, "Date", "date");
          this.addToFilters(C.FILTER_TYPE.NUMBER_RANGE, "Price", "price");
          this.addToFilters(C.FILTER_TYPE.MULTI_SELECTION, "Status", "status", C.STATUS.PAYMENT_STRINGS);
          this.addToFilters(
            C.FILTER_TYPE.MULTI_SELECTION,
            "Product",
            "product",
            Object.values(C.PRODUCT_TYPE)
          );
          this.paymentsLoaded = true;
          if (this.watchesLoaded && this.modelsLoaded) this.loadingDone();
        })
        .catch(error => {
          console.log("Could not load payments: " + error);
          this.loadingDone(error);
        });
    },
    addPaymentToList(paymentData) {
      const payment = {
        id: C.getTrimmedId(paymentData.id),
        date: C.getDateStringFromMillis(paymentData.created * 1000),
        product: paymentData.metadata.productType,
        watchModel: "",
        watchSerial: "",
        status: paymentData.status,
        price: null
      };

      // separate lists for data not shown in table to avoid false positives when searching
      let watchId = paymentData.metadata.watchId;
      let receipt_url = null;
      if (payment.status == "succeeded") {
        payment.price = paymentData.amount;
        payment.priceString = (paymentData.amount / 100).toFixed(2) + "€";
        receipt_url = paymentData.charges.data[0].receipt_url;
      }

      this.receipts.push(receipt_url);
      this.watchIds.push(watchId);

      this.payments.push(payment);
    },
    loadWatches() {
      const id = auth.currentUser.uid;
      db.collection(C.COLLECTION.WATCHES)
        .where("userId", "==", id)
        .get()
        .then(querySnapshot => {
          var watchSerialSelection = [];
          querySnapshot.forEach(doc => {
            const watch = doc.data();
            watch.id = doc.id;
            this.watchesMap.set(watch.id, watch);

            if (watch.serial) watchSerialSelection.push(watch.serial);
          });
          this.addToFilters(
            C.FILTER_TYPE.MULTI_SELECTION,
            "Watch Serial",
            "watchSerial",
            watchSerialSelection
          );

          console.log("watches loaded");
          this.watchesLoaded = true;
          if (this.paymentsLoaded && this.modelsLoaded) this.loadingDone();
        })
        .catch(error => {
          console.log("Could not load watches: " + error);
          this.loadingDone(error);
        });
    },
    loadModels() {
      db.collection(C.COLLECTION.WATCH_CATALOG)
        .get()
        .then(querySnapshot => {
          console.log("GOT WATCH MODELS");
          var watchModelSelection = [];
          querySnapshot.forEach(doc => {
            const model = doc.data();
            model.id = doc.id;
            this.watcheModelsMap.set(model.id, model);
            watchModelSelection.push(model.name);
          });
          this.addToFilters(C.FILTER_TYPE.MULTI_SELECTION, "Watch Model", "watchModel", watchModelSelection);

          console.log("models loaded");
          this.modelsLoaded = true;
          if (this.paymentsLoaded && this.watchesLoaded) this.loadingDone();
        })
        .catch(error => {
          console.log("Could not load models: " + error);
          this.loadingDone(error);
        });
    },
    addToFilters(type, title, dbField, selectionArray) {
      const filter = {
        title: title,
        type: type,
        field: dbField,
        selection: selectionArray
      };
      this.filters.push(filter);
    },
    // after having loaded all the data of payments, watches, models, we can complete the list data for each payment (data is linked together by watchId list)
    finalizeListData() {
      for (var i = 0; i < this.payments.length; i++) {
        const watchId = this.watchIds[i];
        if (watchId) {
          const watch = this.watchesMap.get(watchId);
          const modelId = watch ? watch.modelId : "";
          const serial = watch ? watch.serial : "";


          const watchModel = this.watcheModelsMap.get(modelId);
          this.payments[i].watchModel = watchModel ? watchModel.name : "-";

          this.payments[i].watchSerial = serial ? serial : "";
        } else {
          this.payments[i].watchModel = "MISSING DATA";
        }
      }
    },
    showReceipt(index) {
      window.open(this.receipts[index]);
    },
    // GENERATE PDF
    generatePdf: function (index) {
      if (this.isGeneratingPdf) return;

      const payment = this.realPayments[index];
      console.log(payment);
      const item = this.realPayments[index].items[0];
      const delivery = payment.metadata.delivery ? JSON.parse(payment.metadata.delivery) : {};
      const billing = payment.charges.data[0].billing_details.address;
      const billing_name = payment.charges.data[0].billing_details.name;

      //prices
      // format number to Euro
      let euro = Intl.NumberFormat("en-DE", {
        style: "currency",
        currency: "EUR"
      });

      this.isGeneratingPdf = true;
      const pdfData = {
        request: "generate_invoice",
        responseType: "json",

        // billing
        name: billing_name,
        address_1: billing.line1,
        address_2: billing.line2,
        zip: billing.postal_code,
        city: billing.city,
        country: billing.country,
        state: billing.state,

        // delivery
        delivery_name: delivery.name,
        delivery_address_1: delivery.line1,
        delivery_address_2: delivery.line2,
        delivery_zip: delivery.zip,
        delivery_city: delivery.city,
        delivery_state: delivery.state,
        delivery_country: delivery.country,

        // delivery_name: this.user.delivery.name,
        // delivery_address_1: this.user.delivery.line1,
        // delivery_address_2: this.user.delivery.line2,
        // delivery_zip: this.user.delivery.zip,
        // delivery_city: this.user.delivery.city,
        // delivery_state: this.user.delivery.state,
        // delivery_country: this.user.delivery.country,

        //invoice
        date: payment.created,
        invoice_id: this.payments[index].id,
        description: item.description,
        tax: payment.metadata.taxPercent,
        amount_subtotal: euro.format(item.amount_subtotal / 100),
        amount_tax: euro.format(item.amount_tax / 100),
        amount_total: euro.format(item.amount_total / 100)

        // payment: this.realPayments[index]
      };
      console.log(pdfData);

      axios
        .post("/api/functions.php", pdfData)
        .then(response => {
          if (response.data != "") {
            console.log("PDF READY: /api/" + response.data);
            // saveAs(response.data, 'duke_invoice.pdf');

            const url = "/api/" + response.data;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "duke_invoice.pdf");
            document.body.appendChild(link);
            link.click();
          } else {
            console.log("axios generate pdf: Empty response.");
          }
          this.isGeneratingPdf = false;
        })
        .catch(error => {
          console.log(error);
          this.isGeneratingPdf = false;
        });
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    }
  },

  created() {
    //init
    this.userId = auth.currentUser.uid;

    this.loadPayments();
    this.loadWatches();
    this.loadModels();

    //load user
    var userDocRef = db.collection(C.COLLECTION.USERS).doc(this.userId);
    userDocRef
      .get()
      .then(userDoc => {
        this.$store.commit("setUser", userDoc.data());
      })
      .catch(function (error) {
        console.log("Error getting USER document:", error);
      });
  }
};
</script>

<style scoped lang="scss">
// @import "../../assets/css/platform.scss";
</style>
